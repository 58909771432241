import React from 'react';
import { PageProps } from 'gatsby';
import { Layout } from 'components/shared';
import { NotFoundLayout } from 'components';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import BlogPost from 'templates/blog-post';

const NotFound: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <NotFoundLayout />
    </Layout>
  );
};

export default withUnpublishedPreview(NotFound, {
  templateMap: {
    blog_post: BlogPost,
  },
});
