import styled from 'styled-components';
import { Container, H1 } from '@tuunetech/tuune-components';

export const SearchContainer = styled(Container)`
  margin-top: 73px;

  ${H1} {
    margin-bottom: 73px;
  }

  @media ${({ theme }) => theme.media.desktop} {
    margin: 102px auto;

    ${H1} {
      margin-bottom: 140px;
    }
  }
`;
