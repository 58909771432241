import React from 'react';
import { GridContainer, GridItem, H1 } from '@tuunetech/tuune-components';
import { RestPost } from 'types';
import { SearchContainer } from './styles';
import { PostPreview } from '../PostPreview';
import { RichText } from 'prismic-reactjs';

type BlogSearchLayoutProps = { search: string; posts: RestPost[] };

export const BlogSearchLayout: React.FC<BlogSearchLayoutProps> = ({
  search,
  posts,
}) => {
  return (
    <>
      <SearchContainer>
        <GridContainer>
          <GridItem xs={12}>
            <H1>
              {posts.length
                ? `Results for “${search}”`
                : `No articles found for “${search}”`}
            </H1>
          </GridItem>
          <GridItem xl={5} lg={6} xs={12}>
            {posts.map(({ id, uid, data }) => (
              <PostPreview
                key={id}
                isPrimary
                title={RichText.asText(data.title)}
                excerpt={data.stand_first}
                author={RichText.asText(data.author)}
                path={`/${data.category.data.uid}/${uid}`}
              />
            ))}
          </GridItem>
        </GridContainer>
      </SearchContainer>
    </>
  );
};
